<template>
  <div
    class="list__item"
    v-if="item"
    @click="onJumpDetail"
    :data-tradeno="item.trade_num"
  >
    <div class="item__header">
      <p>
        <strong>订单号：{{ item.trade_num }}</strong>
      </p>
      <template v-if="$bowser.mobile">
        <p
          :class="{
            'sign-red':
              (item.pay_at === 0 && item.invalid_at < now) ||
              Number(item.status) === 1
          }"
        >
          <template v-if="item.pay_at === 0 && item.invalid_at < now">
            订单已超时
          </template>
          <template v-else>
            {{ orderStatusText(Number(item.status)) }}
          </template>
        </p>
      </template>
      <template v-else>
        <p>创建时间：{{ item.created_at | parseTime }}</p>
      </template>
    </div>
    <template v-if="$bowser.mobile">
      <div class="item__body">
        <div
          class="body__item"
          v-for="(product, index) in item.extends"
          :key="index"
        >
          <div class="cover">
            <img :src="product.goods.cover.normal | parseImage" alt="" />
          </div>
          <div class="info">
            <p>
              <strong>{{ product.goods.name }}</strong>
            </p>
            <p>{{ product.extends }}</p>
          </div>
          <div class="price">
            <p>
              <strong>￥ {{ product.goods.price }}</strong>
            </p>
            <p>x {{ product.counts }}</p>
          </div>
        </div>
      </div>
      <div
        class="item__footer"
        v-if="
          !$bowser.mobile &&
            ((item.status === 1 &&
              item.pay_at === 0 &&
              item.invalid_at >= now) ||
              item.status !== 1)
        "
      >
        <el-button
          v-if="Number(item.pay_at) === 0 && item.invalid_at >= now"
          @click.stop="onPay"
          :data-tradeno="item.trade_num"
          >立即支付</el-button
        >
        <el-button v-if="item.pay_at > 0 && Number(item.status) === 5"
          >再次购买</el-button
        >
        <el-button
          v-if="item.pay_at > 0 && Number(item.status) === 3"
          @click.stop="onReceive"
          :loading="requesting"
          >确认收货</el-button
        >
      </div>
    </template>
    <template v-else>
      <div class="item__main-body">
        <div class="body__header">
          <div class="body__item">商品信息</div>
          <div class="body__item">单价</div>
          <div class="body__item">数量</div>
          <div class="body__item">应付金额</div>
          <div class="body__item">订单状态</div>
        </div>
        <table class="body__items">
          <tr v-for="(product, index) in item.extends" :key="index">
            <td>
              <div class="td__inner">
                <div class="product-cover">
                  <img :src="product.goods.cover.normal | parseImage" alt="" />
                </div>
                <div class="product-name">
                  <p>
                    <strong>{{ product.goods.name }}</strong>
                  </p>
                  <p>{{ product.extends }}</p>
                </div>
              </div>
            </td>
            <td>
              <div class="td__inner">￥ {{ product.goods.price }}</div>
            </td>
            <td>
              <div class="td__inner">{{ product.counts }}</div>
            </td>
            <template v-if="index === 0">
              <td :rowspan="item.extends.length">
                <div class="td__inner">
                  <strong>￥ {{ item.total_price }}</strong>
                </div>
              </td>
              <td :rowspan="item.extends.length">
                <div class="td__inner">
                  <div class="order__current-status">
                    <template v-if="item.pay_at === 0 && item.invalid_at < now">
                      订单已超时
                    </template>
                    <template v-else>
                      {{ orderStatusText(Number(item.status)) }}
                    </template>
                  </div>
                  <el-button
                    v-if="item.pay_at === 0 && item.invalid_at >= now"
                    class="order__change-status-bt"
                    @click.stop="onPay"
                    :data-tradeno="item.trade_num"
                    >立即支付</el-button
                  >
                  <el-button
                    v-if="item.pay_at > 0 && Number(item.status) === 3"
                    class="order__change-status-bt"
                    @click.stop="onReceive"
                    :data-tradeno="item.trade_num"
                    >确认收货</el-button
                  >
                </div>
              </td>
            </template>
          </tr>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "c-order-list__item",
  props: {
    item: {
      require: true,
      type: Object,
      default: null
    }
  },
  data() {
    return {
      now: new Date().getTime() / 1000,
      requesting: false
    };
  },
  methods: {
    onJumpDetail(e) {
      const { tradeno } = e.currentTarget.dataset;
      this.$router.push({
        path: "/order",
        query: {
          out_trade_no: tradeno
        }
      });
    },
    onReceive(e) {
      const that = this;
      const { tradeno } = e.currentTarget.dataset;
      that.requesting = true;
      that.$request
        .get("/api/user/order/receive", {
          tradeNum: tradeno
        })
        .then(res => {
          const order = res.data.order;
          that.item.status = order.status;
        })
        .finally(() => {
          that.requesting = false;
        });
    }
  }
};
</script>

<style></style>
