<template>
  <div class="data-loading-container" ref="loadingWrapper">
    <div
      class="loading__text"
      :class="{
        vm: verticalMiddle
      }"
    >
      <i></i>
      <p>加载中</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "c_data-loading",
  props: {
    isPlaceholder: {
      type: Boolean,
      default: true
    },
    verticalMiddle: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.isPlaceholder) {
      this.heightFullScreen(this.$refs.loadingWrapper, false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_mixin";
.data-loading-container {
  .loading__text {
    @include flex-box;
    width: 100%;
    font-size: 0;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    padding-top: 150px;
    &.vm {
      align-items: center;
      padding-top: 0;
    }
    i {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("~@/assets/images/loading.svg") no-repeat center;
      background-size: contain;
      animation: rotate 5s infinite;
    }
    p {
      display: inline-block;
      font-size: 14px;
      line-height: 20px;
      margin-left: 9px;
    }
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
