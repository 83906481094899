<template>
  <order-wrapper>
    <template v-if="lists.length > 0">
      <order-item
        v-for="(item, index) in lists"
        :key="index"
        :item="item"
      ></order-item>
    </template>
    <template v-else-if="!requesting">
      <empty
        title="无订单信息"
        hint="您没有订单信息br您可以在此追踪订单状态"
        :bt="{
          type: 'inner',
          link: '/products',
          title: '继续购物'
        }"
      ></empty>
    </template>
    <order-loading
      v-else-if="requesting"
      class="order-loading"
      :vertical-middle="$bowser.mobile"
    ></order-loading>
    <template v-slot:pagination>
      <pagination
        v-if="paginationConfig && paginationConfig.pageCount > 1"
        class="order-pagination"
        :config="{
          total: paginationConfig.pageCount
        }"
      ></pagination>
    </template>
  </order-wrapper>
</template>

<script>
import orderWrapper from "./wrapper";
import orderItem from "./item";
import pagination from "@/components/Pagination/index.vue";
import orderLoading from "@/components/loading.vue";
import empty from "@/components/empty.vue";
export default {
  name: "c-order-list",
  components: {
    orderWrapper,
    orderItem,
    pagination,
    orderLoading,
    empty
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      page: 1,
      requesting: false,
      lists: [],
      paginationConfig: null,
      status: 0
    };
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    init() {
      this.lists = [];
      this.page = this.$route.query.page;
      this.status = this.$route.params.status || 0;
      this.loadmore();
    },
    loadmore() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .get(that.url, {
            page: that.page,
            status: that.status
          })
          .then(res => {
            const data = res.data;
            that.lists = data.list;
            that.paginationConfig = data._meta;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    }
  }
};
</script>

<style></style>
