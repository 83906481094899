<template>
  <div class="pagination-container" v-if="pageNum > 1">
    <div class="pagination__main-body">
      <div class="main__direction-bt">
        <div
          class="bt--item --prev"
          @click="prev"
          :class="{
            'no-cursor': Number(this.page) === 1
          }"
        >
          &lt;&lt;
        </div>
        <div
          class="bt--item"
          @click="toTop"
          :class="{
            'no-cursor': Number(this.page) === 1
          }"
        >
          First
        </div>
      </div>
      <div class="main_pagination-size">
        <div
          class="site--item"
          v-for="(item, index) in pages"
          :class="{
            acitve: Number(item) === Number(page)
          }"
          :key="index"
          @click="gochange(item)"
        >
          {{ item }}
        </div>
        <div class="site--item no-cursor" v-if="pageNum > showLimit">
          ...
        </div>
      </div>
      <div class="main__direction-bt">
        <div
          class="bt--item"
          @click="toBottom"
          :class="{
            'no-cursor': Number(this.page) === Number(this.pageNum)
          }"
        >
          Last
        </div>
        <div
          :class="{
            'no-cursor': Number(this.page) === Number(this.pageNum)
          }"
          class="bt--item --next"
          @click="next"
        >
          &gt;&gt;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "c_pagination",
  props: {
    config: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      pageNum: this.config.total || 0,
      showLimit: 9
    };
  },
  computed: {
    page() {
      return this.$route.query.page || 1;
    },
    pages() {
      let beginPage = Math.max(0, this.page - Math.ceil(this.showLimit / 2));
      let endPage = beginPage + this.showLimit - 1;
      if (endPage >= this.pageNum) {
        endPage = this.pageNum - 1;
        beginPage = Math.max(0, endPage - this.showLimit + 1);
      }
      const temp = [];
      for (let i = beginPage; i <= endPage; ++i) {
        temp.push(i + 1);
      }
      return temp;
    }
  },
  methods: {
    prev() {
      if (this.page > 1) {
        this.changePage(Number(this.page) - 1);
      }
    },
    next() {
      if (this.page < this.pageNum) {
        this.changePage(Number(this.page) + 1);
      }
    },
    toTop() {
      this.changePage(1);
    },
    toBottom() {
      this.changePage(this.pageNum);
    },
    gochange(num) {
      this.changePage(num);
    },
    changePage(page) {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query = Object.assign(query, {
        page
      });
      this.$router.push({
        path: "",
        query
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "style";
</style>
