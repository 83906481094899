<template>
  <div class="page-common-body order-list-page-container">
    <div class="order-list-main-body">
      <div class="order-list__title">
        <ul class="order-list__types">
          <li
            class="type__item"
            v-for="(item, index) in orderStatus"
            :key="index"
            :class="{
              active: Number(status) === Number(item.val)
            }"
          >
            <router-link
              class="type__link"
              :to="{
                path: item.val === 0 ? '/orders' : `/orders/${item.val}`
              }"
              >{{ item.name }}</router-link
            >
          </li>
        </ul>
        <p class="order-contacts">
          <i class="icon icon-contact"></i>
          <span>联系客服</span>
        </p>
      </div>
      <order-list
        class="order-list__main-body"
        url="/api/user/order/list"
      ></order-list>
    </div>
  </div>
</template>

<script>
import orderList from "./_list/index";
export default {
  name: "page-order-list",
  components: {
    orderList
  },
  data() {
    return {
      orderStatus: [
        {
          name: "全部订单",
          val: 0
        },
        {
          name: "待付款",
          val: 1
        },
        {
          name: "待发货",
          val: 2
        },
        {
          name: "待收货",
          val: 3
        }
      ],
      status: 0
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.status = val.params.status || 0;
      },
      immediate: true
    }
  }
};
</script>

<style></style>
