<template>
  <div class="order-list-container" ref="orderWrapper">
    <slot></slot>
    <slot name="pagination"></slot>
  </div>
</template>

<script>
export default {
  name: "c-order-list__wrapper",
  mounted() {}
};
</script>

<style></style>
